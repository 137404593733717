import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import { trackBackEnd } from 'context/AnalyticsProvider';
import { trapModalFocus } from 'libs/content';
import Portal from 'components/Portal';
import ScreenReaderText from 'components/screen-reader-text';
import IconClose from 'images/icons/Close';
import { fadeIn, fadeOut } from 'libs/animations';
import { usePopup } from 'context/PopupProvider';

//#region Styling
const PopupBackground = styled.div`
	box-sizing: border-box;
	display: flex;
	vertical-align: middle;
	align-items: center;
	padding: 20px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999999;
	background: ${p => transparentize(0.25, p.theme.colors.grey900)};
	margin-top: 0 !important;
	animation-name: ${p => (p.$closingPopup && fadeOut) || fadeIn};
	animation-duration: 150ms;
	animation-fill-mode: forwards;
	opacity: 0;
	@media (max-width: 1309px) {
		padding: ${p => (p.$size === 'default' && '0') || '10px'};
	}

	${p =>
		p.$size === 'default' &&
		css`
			&::after {
				content: '';
				top: 0;
				left: 0;
				position: absolute;
				height: 100%;
				width: 100%;
				z-index: -1;
				pointer-events: none;
				background: ${p =>
					`linear-gradient(0deg, ${
						p.theme.colors.black
					} 0%, ${transparentize(0, p.theme.colors.grey900)} 100%)`};
			}
		`}
`;
const PopupWrapper = styled.div`
	max-width: ${p =>
		(p.$size === 'default' && '100%') ||
		(p.$size === 'small' && '590px') ||
		(p.$size && p.theme.widths[p.$size])};
	height: ${p => (p.$size === 'default' && '100%') || 'auto'};
	max-height: 100%;
	width: 100%;
	margin: 0 auto;
	background-color: ${p => (p.$padding === 'true' ? 'white' : 'transparent')};
	position: relative;
	overflow-y: ${p => (p.$size === 'default' && 'visible') || 'auto'};
	border-radius: ${p => p.theme.utils.borderRadiusLarge};
	box-shadow: ${p =>
		(p.$padding === 'true' && p.theme.utils.boxShadow) || 'none'};

	animation-name: ${p => (p.$closingPopup && fadeOut) || fadeIn};
	animation-duration: 500ms;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
	opacity: 0;

	&:focus {
		outline: 0;
	}
	${p =>
		p.theme.media.smallOnly(css`
			max-width: 100%;
			max-height: 100%;
			/* height: 100%; */
			${p =>
				p.$padding === 'false' &&
				css`
					padding: 0 !important;
				`}
		`)}
`;

const ClosePopup = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	height: 25px;
	padding: 0 5px;
	cursor: pointer;
	z-index: 5;
	background: transparent;
	border: 0;
	${p =>
		p.theme.media.smallOnly(css`
			top: 10px;
			right: 10px;
		`)}

	// Default size (with image-slider, blogposts and success-stories)
	${p =>
		p.$size === 'default' &&
		css`
			top: 10px;
			right: 10px;
			height: 35px !important;
			svg {
				height: 35px !important;
				width: 20px !important;
			}
		`}

	// Circular close button
	${p =>
		p.$variant === 'circular' &&
		css`
			top: 15px;
			right: 15px;
			border-radius: 100%;
			width: 35px;
			height: 35px;
			background-color: ${p => p.theme.colors.white};
			box-shadow: ${p => p.theme.utils.boxShadow};
			transition: opacity 0.3s;
			svg {
				vertical-align: text-bottom !important;
				color: ${p => p.theme.colors.black};
			}
			&:hover {
				opacity: 0.8;
			}
			&:focus {
				outline: 1px dotted ${p => p.theme.colors.black} !important;
				outline-offset: 0px !important;
			}
		`}

	&:focus {
		outline: 1px dotted
			${p =>
				p.$variant === 'white'
					? p.theme.colors.grey700
					: p.theme.colors.grey900};
		outline-offset: 3px;
	}
	&:hover {
		${p =>
			(p.$variant === 'white' && p.theme.colors.blue300) ||
			p.theme.colors.blue600};
	}
	svg {
		height: 25px;
		width: 13px;
		color: ${p =>
			p.$variant === 'white' ? 'white' : p.theme.colors.grey900};
	}
`;

const PopupContentsWrapper = styled.div`
	padding: 45px;
	text-align: left;
	overflow: auto;
	border-radius: ${p => p.theme.utils.borderRadiusLarge};
	width: 100%;
	height: 100%;
	min-height: 200px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 30px;
			max-height: calc(100vh - 20px);
			min-height: 130px;
			> div {
				padding-left: 0;
				padding-right: 0;
			}
		`)}

	// No-padding
	${p =>
		p.$padding === 'false' &&
		css`
			padding: 0 !important;
			overflow: visible;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			> div {
				width: 100%;
			}
		`}



	h2, h3, h4 {
		margin-bottom: 10px;
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	iframe {
		margin: 0 auto;
		max-width: 100%;
	}

	.singular-image {
		text-align: center;
	}

	.slick-slide {
		text-align: center;
	}
`;
//#endregion

/**
 * A React component that displays a popup.
 *
 * @component
 * @param {object} props - The properties that define the component.
 * @param {string} [props.id='popup'] - The ID of the popup.
 * @param {string} [props.title=''] - The title of the popup.
 * @param {string} [props.size='default'] - The size of the popup. Can be 'default' or 'large'.
 * @param {string} [props.padding='true'] - Indicates whether the popup has padding.
 * @param {ReactNode} props.children - The child elements to be displayed in the popup.
 * @param {string} [props.closeButton='black'] - The color of the close button. Can be 'white', 'black' or 'circular'.
 * @param {function} [props.onClose=() => {}] - The function to be called when the popup is closed.
 * @param {object} props.props - The additional properties to be passed to the popup.
 * @returns {ReactNode} The Popup component.
 */
export default function Popup({
	id = 'popup',
	title = '',
	size = 'default',
	padding = 'true',
	children,
	closeButton = 'black',
	onClose = () => {},
	...props
}) {
	const popupRef = useRef(null);
	const [closingPopup, setClosingPopup] = useState(false);

	const { activePopup, setActivePopup, popupLinkRef, popupTrigger } =
		usePopup();

	// Variable for checking if the id of the component is the active-popup
	const open = activePopup === id;

	/**
	 * Handles the keydown event.
	 * If the Escape key is pressed, it closes the popup.
	 *
	 * @param {KeyboardEvent} event - The keydown event.
	 */
	function handleKeyDown(event) {
		if (event.key === 'Escape') {
			handleClose();
		}
	}

	/**
	 * Handles the click event outside the popup.
	 * If the click event target is outside the popup, it closes the popup.
	 *
	 * @param {MouseEvent} event - The click event.
	 */
	function handleClickOutside(event) {
		if (popupRef?.current && !popupRef.current.contains(event.target)) {
			handleClose();
		}
	}

	/**
	 * Closes the popup.
	 * It sets the closingPopup state to true, then after a delay, it sets the activePopup to null and the closingPopup back to false.
	 * It also runs the onClose function if provided.
	 */
	function handleClose() {
		setClosingPopup(true);
		setTimeout(() => {
			setActivePopup(null);
			trackBackEnd('Modal Closed', {
				title: 'Lukk vinduet',
				modal: id,
				context: 'Click',
			});
			const bodyWrapper = document?.getElementsByTagName('body')[0];
			if (bodyWrapper) bodyWrapper.style.overflow = 'visible';
			setClosingPopup(false);
		}, 1000);

		// If function provided then run it
		if (onClose) onClose();
	}

	/**
	 * A React effect hook that adds event listeners for click and keydown events.
	 * It removes the event listeners when the component is unmounted.
	 */
	useEffect(() => {
		if (setActivePopup) setActivePopup(null);
		const bodyWrapper = document?.getElementsByTagName('body')[0];
		if (bodyWrapper) bodyWrapper.style.overflow = 'visible';

		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keydown', handleKeyDown, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keydown', handleKeyDown, true);
		};
		//eslint-disable-next-line
	}, []);

	/**
	 * A React effect hook that handles the opening and closing of the popup.
	 * It sets the focus to the popup when it is opened and back to the original element when it is closed.
	 * It also changes the overflow style of the body element depending on whether the popup is open or not.
	 */
	useEffect(() => {
		const bodyWrapper = document?.getElementsByTagName('body')[0];
		if (bodyWrapper) bodyWrapper.style.overflow = 'visible';

		if (open) {
			popupRef?.current?.focus(); // set focus to popup-ref

			trackBackEnd('Modal Opened', {
				title,
				modal: id,
				context: popupTrigger?.current || 'Click',
			});
			if (bodyWrapper) bodyWrapper.style.overflow = 'hidden';
		}

		if (!open && popupLinkRef) popupLinkRef?.current?.focus(); // set focus back to original - the element that triggered the popup
		//eslint-disable-next-line
	}, [open]);

	// Close popup if triggerClose is true
	useEffect(() => {
		if (!props?.triggerClose || !open) return;
		handleClose();
		//eslint-disable-next-line
	}, [props?.triggerClose]);

	if (!open) return <></>;

	return (
		<Portal>
			<PopupBackground
				$size={size}
				$gradient={props?.gradient}
				$closingPopup={closingPopup}>
				<PopupWrapper
					role="dialog"
					id={id}
					ref={popupRef}
					tabIndex={0}
					aria-labelledby={`${id}__title`}
					onKeyDown={e => trapModalFocus(e, popupRef)}
					$size={size}
					$padding={padding}
					$closingPopup={closingPopup}>
					{title && (
						<div id={`${id}__title`} className="screen-reader-text">
							{title} Klikk på Escape (esc) på tastaturet for å
							lukke visningen.
						</div>
					)}

					<ClosePopup
						role="button"
						aria-label="Lukk popup-vinduet"
						tabIndex="0"
						aria-controls={id}
						$size={size}
						$variant={closeButton || 'black'}
						onClick={() => handleClose()}>
						<ScreenReaderText>Lukk popup-vinduet</ScreenReaderText>
						<IconClose
							size="xl"
							width="13"
							height="25"
							title="Klikk for å lukke popup-vinduet"
						/>
					</ClosePopup>

					<PopupContentsWrapper $padding={padding}>
						{children}
					</PopupContentsWrapper>
				</PopupWrapper>
			</PopupBackground>
		</Portal>
	);
}
